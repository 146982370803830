bovoApp.controller('CustomerController',
  ['$scope', 'Customer', '$location', 'FileUploader', '$localStorage', '$filter', '$routeParams', '$http', 'UserManager',
    function ($scope, Customer, $location, FileUploader, $localStorage, $filter, $routeParams, $http, UserManager) {
      $scope.address = 0;
      $scope.deleted = [];
      $scope.update = {};
      $scope.edit = [];
      $scope.newAdress = {};
      var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
      };
      var mapping = {
        street_number: 'number',
        route: 'street',
        locality: 'state',
        administrative_area_level_1: 'city',
        administrative_area_level_2: 'district',
        country: 'country',
        postal_code: 'zip'
      };

      $scope.selectAll = function (select) {
        if (select) {
          $scope.deleted = [];
          for (let i = 0; i < $scope.data.customers.data.length; i++) {
            $scope.deleted.push($scope.data.customers.data[i].id);
          }
        } else {
          $scope.deleted = [];
        }
      };

      $scope.$on('new_data', function () {
        $scope.newCustomer();
        $('#newCustomer').modal('show');
      }, true);

      $scope.$on('edit_data', function (event, data) {
        $scope.show(data);
      }, true);

      $scope.filterProp = [];

      $scope.filterData = {
        customers: [],
        workers: [],
        workSteps: [],
        page: 1,
        created_at: {
          startDate: null,
          endDate: null
        },
        scheduled_time: {
          startDate: null,
          endDate: null
        }
      }

      $scope.addFilterProps = function (item) {
        if ($scope.filterProp.indexOf(item) === -1)
          $scope.filterProp.push(item);
        else {
          var index = $scope.filterProp.indexOf(item);
          $scope.filterProp.splice(index, 1);
          $scope.filterData[item] = "";
          if ($scope.filterProp.length == 0) {
            $scope.filter('clear');
          }
        }
      }

      $scope.getPage = function (page) {
        $scope.filterData.page = page;
        $scope.filter();
      }

      $scope.sort = function (field, sort) {
        $scope.filterData.field = field;
        $scope.filterData.sort = sort;
        $scope.filter();
      }

      $scope.filter = function (type) {
        if (type == 'clear') {
          $scope.filterData = {
            customers: [],
            workers: [],
            workSteps: [],
            page: 1,
            created_at: {
              startDate: null,
              endDate: null
            },
            scheduled_time: {
              startDate: null,
              endDate: null
            }
          }
          $scope.filterProp = [];
        } else {
          $scope.filterData.filterProp = $scope.filterProp;
          if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
            $scope.filterData.filterProp.created_at = 'created_at';
          }
          if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
            $scope.filterData.filterProp.scheduled_time = 'scheduled_time';
          }
        }
        $scope.init();
      }

      $scope.$on('refresh_data', function () {
        console.log('refreshing customers...')
        $scope.init();
      }, true);

      $scope.init = function () {
        Customer.init($scope.filterData).success(function (data, status, headers, config) {
          $scope.data = data;
          $scope.customer = null;
          if (typeof $scope.filterData.filterProp !== 'undefined') {
            $scope.filterProp = [];
            if (angular.isArray($scope.filterData.filterProp)) {
              for (var i = $scope.filterData.filterProp.length - 1; i >= 0; i--) {
                $scope.filterProp.push($scope.filterData.filterProp[i])
              }
            } else {
              $scope.filterProp.push($scope.filterData.filterProp);
            }
          }
        });
      };

      $scope.passive = function () {
        Customer.passive($scope.filterData).success(function (data, status, headers, config) {
          $scope.data = data;
          $scope.customer = null;
          if (typeof $scope.filterData.filterProp !== 'undefined') {
            $scope.filterProp = [];
            if (angular.isArray($scope.filterData.filterProp)) {
              for (var i = $scope.filterData.filterProp.length - 1; i >= 0; i--) {
                $scope.filterProp.push($scope.filterData.filterProp[i])
              }
            } else {
              $scope.filterProp.push($scope.filterData.filterProp);
            }
          }
        });
      };

      $scope.newCustomer = function () {
        Customer.newCustomer().success(function (data, status, headers, config) {
          $scope.fields = data.fields;
          $scope.workTypes = data.workTypes;
          $scope.stations = data.stations;
          $scope.priceLists = data.priceLists;
          $scope.hjWarehouses = data.hjWarehouses;
          $scope.permissionSteps = [
            {
              id: 'created',
              name: 'Kargo Oluşturma',
              sms: false,
              email: false
            }
          ];
          if (data.workSteps.length > 0) {
            data.workSteps.forEach(element => {
              $scope.permissionSteps.push({
                id: element.id,
                name: element.name,
                sms: false,
                email: false
              })
            });
          }


          $scope.customer = {
            prices: []
          }
          $scope.workTypes.forEach(element => {
            $scope.customer.prices.push({
              work_type: element.id,
              type: 'weight'
            })
          });
        });
      };

      var logo = $scope.logo = new FileUploader({
        url: '/api/upload-customer-logo',
        headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
        autoUpload: true
      });

      logo.filters.push({
        name: 'formatFilter',
        fn: function (item /*{File|FileLikeObject}*/, options) {
          var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
          return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
      });

      logo.onAfterAddingFile = function (fileItem) {
        $scope.proccessProfile = true;
        $scope.pending = true;
      };

      logo.onCompleteItem = function (item, response, status, headers) {
        $scope.proccessProfile = false;
        $scope.customer.logo = response.file;
        if (response.status == 'success')
          $scope.pending = false;
      }

      $scope.getAddress = function (gAddress) {
        if (typeof gAddress.latitude !== 'undefined') {
          var location = new google.maps.LatLng(gAddress.latitude, gAddress.longitude);
          // $scope.map.setCenter(location);
          // $scope.marker.setDuration(1000);
          // $scope.marker.setEasing("swing");
          // $scope.marker.setPosition(location);
          $scope.customer.latitude = gAddress.latitude;
          $scope.customer.longitude = gAddress.longitude;
          $scope.customer.city = gAddress.city;
          $scope.customer.state = gAddress.state;
          var address = ''
          if (gAddress.street) {
            address += gAddress.street + ' '
          }
          if (gAddress.number) {
            address += gAddress.number
          }
          $scope.customer.zipcode = gAddress.zipcode ? gAddress.zipcode : gAddress.zip;
          $scope.customer.address = address;
          $scope.states.forEach(element => {
            if (element.abbreviation == gAddress.city) {
              $scope.customer.state = element.name;
              return true;
            }
          });
        }
      }

      $scope.saveCustomer = function (customer) {
        Customer.saveCustomer(customer).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          // $location.path('musteri-yonetimi');
          $('#newCustomer').modal('hide');
          $scope.init()
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        });
      };

      $scope.delete = function (type) {
        if (type == 'set') {
          $('#delete').modal('show');
        } else if (type == 'delete') {
          var txt;
          var r = confirm("Silmek istediğinize emin misiniz ? Bu işlem geri alınamaz bir işlemdir!");
          if (r == true) {
            Customer.deleteCustomer($scope.deleted).success(function (data, status, headers, config) {
              Messenger().post(data.message);
              $scope.init();
              $('#delete').modal('toggle');
              $scope.deleted = [];
            }).error(function (data) {
              Messenger().post({ message: data.message, type: "error" });
            });
          }
        } else if (type == 'all') {
          var txt;
          var r = confirm("Tüm müşterileri silmek istediğinize emin misiniz ? Bu işlem geri alınamaz bir işlemdir!");
          if (r == true) {
            Customer.deleteCustomer({ type: 'all' }).success(function (data, status, headers, config) {
              Messenger().post(data.message);
              $scope.init();
              $('#delete').modal('toggle');
              $scope.deleted = [];
            }).error(function (data) {
              Messenger().post({ message: data.message, type: "error" });
            });
          }
        }
      };

      $scope.deleteAddress = function (id) {
        Customer.deleteAddress({ id: id }).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          var index = $scope.customer.addresses.indexOf($filter('filter')($scope.customer.addresses, { id: id })[0]);
          if (index > -1)
            $scope.customer.addresses.splice(index, 1);
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        })
      };

      $scope.updateAddress = function () {
        Customer.updateAddress($scope.update).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.update = {};
          $scope.edit = [];
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        })
      };

      $scope.updateCustomer = function () {
        $scope.customer.permissionSteps = $scope.permissionSteps;
        Customer.updateCustomer($scope.customer).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $('#editCustomer').modal('hide');
          $scope.getCustomerDetails($scope.customer.id);
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        })
      };

      $scope.addAddress = function () {
        $scope.newAdress.company_customer_id = $scope.customer.id;
        Customer.addAddress($scope.newAdress).success(function (data, status, headers, config) {
          Messenger().post(data.message);
          $scope.customer = data[0];
          $scope.newAdress = {};
        }).error(function (data) {
          Messenger().post({ message: data.message, type: "error" });
        });
      };

      $scope.show = function (data) {
        Customer.show(data.id).success(function (data, status, headers, config) {
          $scope.customer = data.customer;
          if (data.customer.hj_warehouse) {
            var findWr = data.hjWarehouses.find(i => i.xdock == data.customer.hj_warehouse.xdock)
            if (findWr) {
              $scope.customer.hj_warehouse_id = findWr.id;
            }
          }

          $scope.chart = data.chart;
          $scope.fields = data.fields;
          $scope.workTypes = data.workTypes;
          $scope.stations = data.stations;
          $scope.priceLists = data.priceLists;
          $scope.hjWarehouses = data.hjWarehouses;
          $scope.permissionSteps = [
            {
              id: 'created',
              name: 'Kargo Oluşturma',
              sms: false,
              email: false
            }
          ];
          if (data.workSteps.length > 0) {
            data.workSteps.forEach(element => {
              $scope.permissionSteps.push({
                id: element.id,
                name: element.name,
                sms: false,
                email: false
              })
            });
          }

          $scope.permissionSteps.forEach(element => {
            var index = $scope.customer.sms_email_permissions.findIndex(function (item) {
              return item.step == element.id;
            })
            if (index > -1) {
              element.sms = $scope.customer.sms_email_permissions[index].sms;
              element.email = $scope.customer.sms_email_permissions[index].email;
            }
          });

          $('#editCustomer').modal('show');
          $scope.customer.email = $scope.customer.user.email;

        });
      }

      $scope.$on('new_order', function (event, data, nextParams, fromState) {
        var index = $scope.customer.orders.indexOf($filter('filter')($scope.customer.orders, { id: data.order.id })[0]);
        if (index > -1)
          var order = $scope.customer.orders[index];
        if (typeof order !== 'undefined')
          angular.merge(order, data.order);
        else
          $scope.customer.orders.unshift(data.order);
      });

      $scope.$on('updateOrder', function (event, data, nextParams, fromState) {
        var index = $scope.customer.orders.indexOf($filter('filter')($scope.customer.orders, { id: data.id })[0]);
        if (index > -1)
          var order = $scope.customer.orders[index];
        angular.merge(order, data);
      });

      $scope.$on('deleteOrder', function (event, data, nextParams, fromState) {
        var index = $scope.customer.orders.indexOf($filter('filter')($scope.customer.orders, { id: data.id })[0]);
        if (index > -1)
          $scope.customer.orders.splice(index, 1);
      });

      $scope.toggleSelection = function toggleSelection(id) {
        var idx = $scope.deleted.indexOf(id);
        if (idx > -1) {
          $scope.deleted.splice(idx, 1);
        }
        else {
          $scope.deleted.push(id);
        }
      };

      $scope.donwloadSampleExcel = function () {
        Customer.donwloadSampleExcel().success(function (data, status, headers, config) {
          window.location.href = data.url;
        });
      };

      $scope.importCustomerList = function () {
        $scope.upload = true;
        var fd = new FormData();
        var imgBlob = dataURItoBlob($scope.uploadme);
        fd.append('file', imgBlob);
        $http.post(
          'api/import-customer-list',
          fd,
          {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
              'X-CSRF-TOKEN': CSRF_TOKEN
            }
          }
        )
          .success(function (response) {
            Messenger().post(response.message);
            setTimeout(function () {
              location.reload();
            }, 800);

          })
          .error(function (response) {
            Messenger().post({ message: response.message, type: "error" });
          })
          .finally(function (data) {
            $scope.upload = false;
          });

      };

      $scope.uploadme;

      $scope.uploadImageCustomer = function (customer) {
        $scope.upload = true;
        var fd = new FormData();
        var imgBlob = dataURItoBlob(customer.imageUp);
        fd.append('file', imgBlob);
        fd.append('customer', customer.id);
        $http.post(
          'api/upload-image-customer',
          fd,
          {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
              'X-CSRF-TOKEN': CSRF_TOKEN
            }
          }
        )
          .success(function (response) {
            $scope.customer.logo = response.logo;
            console.log($scope.customer);
          });
      };

      function dataURItoBlob(dataURI) {
        var binary = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {
          type: mimeString
        });
      };

      $scope.downloadExcel = function () {

        Customer.generateExcelReport({ customer: $scope.deleted }).success(function (data, status, headers, config) {
          /*
          window.location.href = data['url'];
          Customer.removeDownloadFile({path: data['path']}).success(function(response, status, headers, config) {  
            $scope.deleted = [];
          });
          */
        });
        Messenger().post({ message: "Excel dosyanız oluşturuluyor. Tamamlandığında mail adresinize gönderilecektir. Bu işlem yaklaşık 1 - 5 dk kadar sürer." });
      };

      $scope.toggleStatus = function toggleStatus(event) {

        if (event.target.checked) {

          $scope.deleted = [];

          for (var i = 0; i < $scope.data.customers.data.length; i++) {

            $scope.deleted.push($scope.data.customers.data[i].id);
          }
        } else {

          $scope.deleted = [];

        }
      };

      $scope.addPrice = function (workType, type) {
        $scope.customer.prices.push({
          min_weight: null,
          type: 'weight',
          work_type: workType,
          price_type: type
        });
      }

      $scope.removePrice = function (item) {
        var index = $scope.customer.prices.findIndex(function (elm) {
          return elm.$$hashKey == item.$$hashKey
        })

        console.log(index)

        $scope.customer.prices.splice(index, 1);

      }
    }
  ]);