bovoApp.controller('OrderWarningController', ['$scope', 'Order', '$location', '$filter', '$routeParams', '$rootScope',
	function ($scope, Order, $location, $filter, $routeParams, $rootScope) {

		$scope.filterProp = [];
		$scope.selectedItem = [];

		$scope.calendarView = 'month';

		$scope.addSelectedItem = function (item) {

			if ($scope.selectedItem.indexOf(item) > -1) {
				$scope.selectedItem.splice($scope.selectedItem.indexOf(item), 1);
			} else {
				$scope.selectedItem.push(item);
			}
		}

		$scope.filterData = {
			type: '',
			page: 1,
			created_at: {
				startDate: null,
				endDate: null
			},
			scheduled_time: {
				startDate: null,
				endDate: null
			}
		}

		$scope.addFilterProps = function (item) {
			if ($scope.filterProp.indexOf(item) === -1)
				$scope.filterProp.push(item);
			else {
				var index = $scope.filterProp.indexOf(item);
				$scope.filterProp.splice(index, 1);
				if (item != 'term') {
					$scope.filterData[item] = [];
				} else {
					$scope.filterData[item] = "";
				}
				if (item == 'date') {
					delete $scope.filterData.startDate;
					delete $scope.filterData.endDate;
				}
				if ($scope.filterProp.length == 0) {
					$scope.filter('clear');
				}
			}
		}

		$scope.getPage = function (page) {
			$scope.filterData.page = page;
			$scope.filter();
		}

		$scope.sort = function (field, sort) {
			$scope.filterData.field = field;
			$scope.filterData.sort = sort;
			$scope.filter();
		}

		$scope.filter = function (type) {
			if (type == 'clear') {
				$scope.filterData = {
					customers: [],
					workers: [],
					workSteps: [],
					page: 1,
					created_at: {
						startDate: null,
						endDate: null
					},
					scheduled_time: {
						startDate: null,
						endDate: null
					}
				}
				$scope.filterProp = [];
			} else {
				$scope.filterData.filterProp = $scope.filterProp;
				if (typeof $scope.filterData.created_at.endDate !== 'undefined' && $scope.filterData.created_at.endDate !== null) {
					$scope.filterData.filterProp.created_at = 'created_at';
				}
				if (typeof $scope.filterData.scheduled_time.endDate !== 'undefined' && $scope.filterData.scheduled_time.endDate !== null) {
					$scope.filterData.filterProp.scheduled_time = 'scheduled_time';
				}
			}
			$scope.warnings();
		}

		$scope.$on('refresh_data', function () {
			console.log('refreshing warnings...')
			$scope.warnings();
		}, true);

		$scope.clearFilter = function (page) {
			$scope.filterData = {
				workers: [],
				workSteps: [],
				page: 1,
				created_at: {
					startDate: null,
					endDate: null
				},
				scheduled_time: {
					startDate: null,
					endDate: null
				}
			}
			$scope.warnings();
		}

		$scope.warnings = function () {
			if ($routeParams.type) {
				$scope.filterData.type = $routeParams.type;
			}
			Order.warnings($scope.filterData).success(function (data, status, headers, config) {
				$scope.data = data;
				$scope.data.steps.unshift(
					{
						id: 0,
						name: 'Veri Oluşturuldu'
					},
					{
						id: 'edit',
						name: 'Düzenlendi'
					},
					{
						id: 'rate',
						name: 'Puanlandı'
					},
					{
						id: 'create',
						name: 'Veri Oluşturuldu'
					},
					// {
					// 	id : 'change_date',
					// 	name : 'Tarihi Değiştirildi'
					// }
				);
			});
		};

		$scope.downloadExcel = function () {
			Order.generateExcelReportNotification($scope.filterData).success(function (data, status, headers, config) {
				window.location.href = data['url'];
			});
		};

	}
]);