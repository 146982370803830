(function (module) {
    module.directive('uploadDocument', function ($timeout) {
        return {
            restrict: 'AE',
            scope: {
                model: '=',
                modelType: '=',
                modelId: '='
            },
            controller: function ($rootScope, $scope, FileUploader) {

                $scope.invoiceAmount = null;
                if ($scope.modelType == "App\\Model\\RouteOptimization") {
                    $scope.selectOptions = [
                        'Teslim Formu'
                    ];
                } else if ($scope.modelType == "App\\Model\\Order") {
                    $scope.selectOptions = [
                        'Fatura',
                        'Tutanak'
                    ];
                } else if ($scope.modelType == "App\\Model\\OrderCompensate") {
                    $scope.selectOptions = [
                        'Fatura',
                        'Dilekçe'
                    ];
                } else {
                    $scope.selectOptions = [
                        'Vergi Levhası',
                        'İmza Sirküsü - İmza Beyannamesi',
                        'Hesap Cüzdanı',
                        'Faaliyet Belgesi',
                        'Kargo Sözleşmesi',
                        'Diğer'
                    ];
                }

                $scope.documentUploader = new FileUploader({
                    url: '/api/upload-documents',
                    headers: {
                        'X-CSRF-TOKEN': CSRF_TOKEN
                    },
                    autoUpload: true
                });

                $scope.documentType = $scope.selectOptions[0];
                $scope.documentUploader.onBeforeUploadItem = function (item) {
                    console.log(item)
                    console.log($scope.invoiceAmount)
                    item.formData = [{
                        model_id: $scope.modelId,
                        model_type: $scope.modelType,
                        type: $scope.documentType,
                        invoice_amount: $scope.invoiceAmount
                    }];
                };

                $scope.formError = false;
                $scope.documentUploader.filters.push({
                    name: 'formatFilter',
                    fn: function (item /*{File|FileLikeObject}*/, options) {
                        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        if ('|jpg|jpeg|png|pdf|doc|'.indexOf(type) === -1) {
                            if ($scope.modelType == "App\\Model\\Order") {
                                $scope.formError = 'Sadece jpg,png,pdf,doc formatları desteklenmektedir.';
                            } else {
                                alert('Sadece jpg,png,pdf,doc formatları desteklenmektedir.');
                            }
                            setTimeout(() => {
                                $scope.formError = false;
                            }, 2000);
                            return false;
                        };
                        return true;
                    }
                });

                $scope.documentUploader.onAfterAddingFile = function (fileItem) {
                    $scope.progress = true;
                    $scope.pending = true;
                };

                $scope.setPrice = function (price) {
                    console.log(price)
                };

                $scope.formSuccess = false;
                $scope.documentUploader.onCompleteItem = function (item, response, status, headers) {
                    $scope.progress = false;
                    console.log($scope.model)
                    $scope.invoiceAmount = null;
                    $scope.formSuccess = 'İşleminiz başarıyla gerçekleşti.';
                    setTimeout(() => {
                        $scope.formSuccess = false;
                    }, 2000);
                    $scope.model.documents.push(response.document);
                    if (response.status == 'success') {
                        $scope.pending = false;
                    }
                }
            },
            templateUrl: "modules/directive/upload-document.html",

        };
    });
}(angular.module("uploadDocument", [])));